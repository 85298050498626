<template>
  <v-container
    class="pt-0"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card
          style="width: 100%; height: 100%"
          class="pa-2"
        >
          <v-card-title
            class="text-h3 font-weight-bold grey--text mt-2 pb-1 ml-4"
          >
            Equipment Models
          </v-card-title>
          <v-data-table
            v-model="selectedTableItems"
            class="row-pointer mx-8"
            :headers="headers"
            single-select
            item-key="name"
            :loading="loadingTable"
            loading-text="Retrieving models"
            :items="models"
            :search="tableSearchValue"
            :fixed-header="true"
            @click:row="
              (item, row) => {
                row.select(true)
                selectedTableItem = item
              }
            "
          >
            <template v-slot:[`body.prepend`]>
              <tr class="no-pointer">
                <td>
                  <v-text-field
                    v-model="tableFieldName"
                    type="text"
                    class="pt-4"
                    outlined
                    dense
                    label="filter..."
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldUSize"
                    type="number"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="tableFieldCapacity"
                    type="number"
                    class="pt-4"
                    outlined
                    dense
                  />
                </td>
              </tr>
            </template>
            <template v-slot:[`item.imagePath`]="props">
              <td>
                <v-btn
                  text
                  color="green lighten-1"
                  :disabled="props.item.imagePath ? false : true"
                  @click.stop="() => {
                    showImage = true
                    imageSrc = props.item.imagePath
                  }
                  "
                >
                  <v-icon>
                    {{ mdiImage }}
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
          <div class="d-flex mb-4 justify-center">
            <v-btn
              text
              class="font-weight-bold"
              color="green"
              @click="showAddModelDialog = true"
            >
              Add
            </v-btn>
            <v-btn
              text
              :disabled="Object.keys(selectedTableItem).length === 0"
              :loading="checkingCanEditModel"
              color="blue"
              class="font-weight-bold"
              @click="openEditModelDialog"
            >
              Edit
            </v-btn>
            <v-btn
              text
              class="font-weight-bold"
              :disabled="Object.keys(selectedTableItem).length === 0"
              color="red"
              @click="showDeleteModelDialog = true"
            >
              Delete
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-alert
          v-if="showAlert"
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAddModelDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
        style="overflow-y: scroll;"
        height="70vh"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Add New Equipment Model
        </v-card-title>
        <ewc-model-flow
          :local-bus="localBus"
          @cancel-build-model="
            showAddModelDialog = false
            showBuildModelAlert = false
          "
        />
      </v-card>
      <v-alert
        v-if="showBuildModelAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ buildModelAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showEditModelDialog"
      width="600px"
      persistent
    >
      <v-card
        class="pa-6"
        style="overflow-y: scroll;"
        height="70vh"
      >
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Edit Equipment Model
        </v-card-title>
        <ewc-model-flow
          :key="showEditModelDialog"
          :local-bus="localBus"
          :edit-name-only="editNameOnly"
          :initial-state-object="selectedTableItem"
          @cancel-build-model="
            showEditModelDialog = false
            showEditModelAlert = false
          "
        />
      </v-card>
      <v-alert
        v-if="showEditModelAlert"
        :type="editModelAlertType"
        class="mt-2 mb-0"
      >
        {{ editModelAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showDeleteModelDialog"
      max-width="750"
      persistent
    >
      <v-card
        v-if="selectedTableItem"
        class="pa-4 px-4"
      >
        <v-card-title class="text-h5 mb-3 justify-center">
          Are you sure you want to delete: &nbsp; <span>{{ selectedTableItem.name + '?' }}</span>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="red darken-1"
            text
            class="font-weight-bold"
            :loading="deletingModel"
            @click="deleteModel"
          >
            Delete
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            class="font-weight-bold"
            :disabled="deletingModel"
            @click="
              showDeleteModelDialog = false
              showDeleteAlert = false
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showDeleteAlert"
        type="error"
        class="mt-2 mb-0"
      >
        {{ deleteAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showImage"
      max-width="500"
    >
      <v-sheet
        class="d-flex justify-center pa-4"
        width="500"
        height="500"
      >
        <v-img
          :src="imageSrc"
          max-width="475"
          max-height="475"
          contain
        />
      </v-sheet>
    </v-dialog>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import common from '@/api/common.js'
  import equipmentApi from '@/api/equipment.js'
  import ewcApi from '@/api/ewc.js'
  import { mdiImage } from '@mdi/js'

  export default {
    name: 'EWCModels',
    data: () => ({
      localBus: new Vue(),

      tableFieldName: '',
      tableFieldUSize: '',
      tableFieldCapacity: '',
      tableSearchValue: '',
      loadingTable: false,
      models: [],
      selectedTableItem: {},
      selectedTableItems: [],
      imageSrc: '',
      showImage: false,

      showAlert: false,
      alertMessage: '',
      alertMessageGeneral: 'We are unable to process your request at this time. Please try again later.',
      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageAddModelSuccess: 'Successfully added model!',
      snackBarMessageDeleteModelSuccess: 'Equipment model deleted successfully!',
      showBuildModelAlert: false,
      buildModelAlertMessage: '',

      showAddModelDialog: false,

      showDeleteModelDialog: false,
      deletingModel: false,
      showDeleteAlert: false,
      deleteAlertMessage: '',

      checkingCanEditModel: false,
      showEditModelAlert: false,
      editModelAlertType: 'error',
      editModelAlertMessage: '',
      editNameOnly: false,
      showEditModelDialog: false,
      editModelAlertMessageModelInUse: 'This model is in use by equipment on your sites. Only the model name and image can be edited at this time.',

      mdiImage: mdiImage,

    }),
    computed: {
      headers () {
        return [
          {
            text: 'Equipment Name',
            align: 'left',
            value: 'name',
            filter: value => {
              if (!this.tableFieldName) return true
              return (value).toLowerCase().includes(this.tableFieldName.toLowerCase())
            },
          },
          {
            text: 'U Size',
            value: 'usize',
            filter: value => {
              if (!this.tableFieldUSize) return true
              return value.toString().includes(this.tableFieldUSize)
            },
          },
          {
            text: 'Capacity',
            value: 'capacity',
            filter: value => {
              if (!this.tableFieldCapacity) return true
              return value.toString().includes(this.tableFieldCapacity)
            },
          },
          {
            text: 'Image',
            value: 'imagePath',
          },
        ]
      },
    },
    created () {
      this.getModels()

      this.localBus.$on('showBuildModelAlertMessage', alertMessage => {
        this.showBuildModelAlert = true
        this.buildModelAlertMessage = alertMessage
      })
      this.localBus.$on('showEditModelAlertMessage', alertMessage => {
        this.showEditModelAlert = true
        this.editModelAlertType = 'error'
        this.editModelAlertMessage = alertMessage
      })
      this.localBus.$on('addModelSuccess', successMessage => {
        this.showSnackBar = true
        this.snackBarMessage = successMessage
        this.showAddModelDialog = false
        this.getModels()
      })
      this.localBus.$on('editModelSuccess', successMessage => {
        this.showSnackBar = true
        this.snackBarMessage = successMessage
        this.showEditModelDialog = false
        this.selectedTableItem = {}
        this.selectedTableItems = []
        this.getModels()
      })
      this.localBus.$on('removeModelAlertMessage', () => {
        this.showBuildModelAlert = false
        this.showEditModelAlert = false
      })
    },
    methods: {
      getModels: function () {
        this.loadingTable = true
        equipmentApi.getEquipmentModels()
          .then((response) => {
            console.log(response)
            this.loadingTable = false
            this.models = response.data
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getModels()
            } else {
              this.loadingTable = false
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
      },

      deleteModel () {
        this.deletingModel = true
        let skipFinallyBlock = false
        ewcApi.deleteEquipmentModel(this.selectedTableItem.id)
          .then((response) => {
            console.log(response)
            this.showDeleteModelDialog = false
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageDeleteModelSuccess
            this.getModels()
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.deleteModel()
            } else {
              this.showDeleteAlert = true
              if (error.response.status === 409) {
                this.deleteAlertMessage = `${error.response.data}. We are unable to delete the model at this time.`
              } else {
                this.deleteAlertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.deletingModel = false
            }
          })
      },

      openEditModelDialog () {
        this.checkingCanEditModel = true
        this.showEditModelAlert = false
        const skipFinallyBlock = false
        ewcApi.canEditEquipmentModel(this.selectedTableItem.id)
          .then((response) => {
            console.log(response)
            this.editNameOnly = !response.data.editable
            if (this.editNameOnly) {
              this.showEditModelAlert = true
              this.editModelAlertType = 'info'
              this.editModelAlertMessage = this.editModelAlertMessageModelInUse
            }

            this.showEditModelDialog = true
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.openEditModelDialog()
            } else {
              this.showEditModelAlert = true
              this.editModelAlertType = 'error'
              this.editModelAlertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.checkingCanEditModel = false
            }
          })
      },
    },
  }
</script>
